<template>
  <v-col cols="12" md="4" sm="6">
    <div class="text-center">
      <v-dialog v-model="dialog" width="500">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2"> Preview </v-card-title>
          <!--BEGIN display attachment-->
          <v-card-text v-if="dialog" class="attachment">
            <!--BEGIN display pdf-->
            <div v-if="base64Ext === 'pdf'">
              <pdf
                v-for="i in pageCount(base64)"
                :key="i"
                :page="i"
                :src="
                  base64.length !== 0
                    ? `data:${mimeType};base64, ${base64}`
                    : imageUrl
                "
                class=""
              ></pdf>
            </div>
            <!--END display pdf-->
            <!--BEGIN display image-->
            <div v-else-if="isImage">
              <v-img
                :src="
                  base64.length !== 0
                    ? `data:${mimeType};base64, ${base64}`
                    : imageUrl
                "
                class="pa-9"
              />
            </div>
            <div v-else>
              <div class="d-flex flex-column">
                <v-btn
                  class="primary align-self-center mt-3"
                  @click="
                    () => {
                      base64.length !== 0
                        ? download(base64)
                        : downloadLink(imageUrl);
                    }
                  "
                >
                  Download File
                  <v-icon class="mx-2">cloud_download</v-icon>
                </v-btn>
              </div>
            </div>
            <!--END display image-->
          </v-card-text>
          <!--END display attachment-->
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="error" text v-on:click="setDialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-col>
</template>
<script>
import pdf from "vue-pdf";
import helpers from "@kinetics254/cassandra-base/utilities/helpers/helper";
import FileMixin from "@kinetics254/cassandra-base/mixins/FileMixin.js";

export default {
  components: {
    pdf,
  },
  mixins: [FileMixin],
  name: "AddingImprestImagePreview",
  props: {
    routeParams: String,
    isEdit: Boolean,
    documentType: String,
    base64: String,
    dialog: Boolean,
  },

  data: function () {
    return { imageUrl: "", mimeType: "", base64Ext: "" };
  },
  computed: {
    setDialog: {
      /**
       * Open dialog
       * @returns {boolean}
       */
      get() {
        return this.dialog;
      },
      /**
       * Close dialog
       * @param val
       */
      set(val) {
        this.$emit("close-dialog", val);
      },
    },
    isImage() {
      return /png|jpeg|xml|gif|svg|jpg/.test(this.base64Ext);
    },
  },
  methods: {
    /**
     * Get image mime type
     * @param base64
     * @returns {*}
     */
    getMimeType(base64) {
      helpers.getFileType(base64).then((res) => {
        this.mimeType = res.mime;
        this.base64Ext = res.ext;
      });
    },
    /**
     * Display attachment
     * @returns {string|string}
     */
    displayAttachment() {
      if (this.base64.length !== 0) {
        return this.base64
          ? `data:${this.mimeType};base64, ${this.base64}`
          : this.imageUrl;
      }
    },
    getFileExt(fileLink) {
      return fileLink.split(".")[1];
    },
    getFileLink(fileLink) {
      return process.env.VUE_APP_FILE_API + fileLink;
    },
    downloadLink(fileLink) {
      const a = document.createElement("a");
      a.href = process.env.VUE_APP_FILE_API + fileLink;
      a.download = process.env.VUE_APP_FILE_API + fileLink;
      a.click();
    },
    download(item) {
      var a = document.createElement("a");
      helpers.getFileType(item);
      a.href = `data:${this.mimeType};base64,${item}`;
      a.download = `Attachment.${this.base64Ext}`;
      a.click();
    },
  },
  watch: {
    base64: {
      handler(n, o) {
        if (n !== "") {
          this.getMimeType(n);
        }
      },
    },
  },
};
</script>
