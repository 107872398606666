<template>
  <v-container>
    <v-card tile elevation="0">
      <v-card-title class="overline">
        <v-btn text @click="$router.back()">
          <v-icon left>mdi-arrow-left</v-icon>
          Back
        </v-btn>

        <v-spacer />

        <span> Imprest number: {{ imprestNo }}</span>

        <v-spacer />

        <v-btn
          @click="addNewAccountingItem"
          color="info"
          class="mr-2 capitalize-text"
        >
          Account for more...
        </v-btn>
        <v-btn
          @click="submitAccount"
          color="primary"
          class="mr-2 capitalize-text"
        >
          Submit
        </v-btn>
      </v-card-title>

      <v-divider class="mb-md-3" />

      <!--BEGIN table card--->
      <v-card-text>
        <v-data-table :headers="headers" :items="imprest">
          <template v-slot:item.expenseCode="{ item }">
            <v-autocomplete
              v-if="item.extraLine"
              @change="selectExpenseCode"
              @focusout="autosaveExpenseItem"
              v-model="expenseItem.expenseCode"
              :items="expenseItems"
              :item-text="(item) => `${item.code} - ${item.description}`"
              :item-value="(item) => item.code"
              :hide-details="false"
              label="Select Expense Item"
              dense
              single-line
              class="mb-n3"
            ></v-autocomplete>
            <span v-else>{{ item.expenseCode }}</span>
          </template>
          <template v-slot:item.description="{ item }">
            <v-text-field
              v-if="item.extraLine"
              @focusout="autosaveExpenseItem"
              v-model.number="expenseItem.description"
              :hide-details="true"
              dense
              single-line
            ></v-text-field>
            <span v-else>{{ item.description }}</span>
          </template>
          <template v-slot:item.Quantity="{ item, index }">
            <v-text-field
              @focusout="autosaveExpenseItem"
              type="number"
              @input="assignQuantity($event, index)"
              onkeyup="if(this.value<0){this.value= this.value * -1}"
              min="1"
              v-model.number="expenseItem.Quantity"
              :hide-details="true"
              dense
              single-line
              v-if="item.extraLine"
            ></v-text-field>
            <span v-else>{{ item.Quantity }}</span>
          </template>
          <!--eslint-disable-next-line -->
          <template v-slot:item.amountToPay="{ item }">
            <span>{{ item.amountToPay | currencyFormat }}</span>
          </template>
          <!--eslint-disable-next-line -->
          <template v-slot:item.balance="{ item }">
            <span>{{ item.balance | currencyFormat }}</span>
          </template>
          <!--eslint-disable-next-line -->
          <!-- <template v-slot:item.status="{ item }">
            <v-icon small left :color="statusColorCode(item)">
              mdi-radiobox-marked
            </v-icon>
            <template>
              {{
                item.detailedImprestLines.length > 0
                  ? "accounted"
                  : "not accounted"
              }}
            </template>
          </template> -->
          <!--END status slot-->
          <!--BEGIN action slot-->
          <!--eslint-disable-next-line -->
          <template v-slot:item.action="{ item }">
            <div class="">
              <v-btn
                v-if="item.extraLine"
                color="secondary"
                small
                @click="addDimention(item)"
                class="mx-1 white--text text-lowercase"
                elevation="0"
                :disabled="!expenseItem.expenseCode || !expenseItem.description"
              >
                <v-icon small class="mr-2">mdi-abacus</v-icon> Dimensions
              </v-btn>
              <!--BEGIN view button-->
              <v-btn
                color="success"
                small
                :to="{
                  name: 'ReceiptAccounting',
                  params: { code: item.lineNo },
                  query: { document: item.documentNo },
                }"
                class="mx-1 white--text text-lowercase"
                elevation="0"
              >
                <v-icon small class="mr-2 white--text"> mdi-eye</v-icon>
                view
              </v-btn>
              <!--END view button-->

              <!--BEGIN account button-->
              <v-btn
                color="primary"
                small
                @click="attach(item.lineNo)"
                class="mx-1 white--text text-lowercase"
                elevation="0"
                :disabled="item.Purchase"
              >
                <v-icon small class="mr-2">mdi-cash</v-icon> account
              </v-btn>
              <v-btn
                v-if="item.extraLine"
                color="info"
                small
                @click="saveAccountingItem(item)"
                class="mx-1 white--text text-lowercase"
                elevation="0"
                :disabled="!expenseItem.expenseCode || !expenseItem.description"
              >
                <v-icon small class="mr-2">mdi-plus</v-icon> add
              </v-btn>
              <!--END account button-->
            </div>
          </template>
          <!--END action slot-->
        </v-data-table>
      </v-card-text>
      <!--BEGIN calculate imprest-->
      <calc-imprest
        :imprestLine="imprestLine"
        :Currency="Currency"
        :dialog="dialog"
        v-on:close-dialog="closeDialog"
      />
      <!--END calculate imprest-->
      <!--BEGIN accounted list-->
      <accounted-list
        :imprestLines="accountedImprest"
        :dialog="accountsDialog"
        :imprestNo="imprestNo"
        :Currency="Currency"
        v-on:close-dialog="closeAccountedList"
      />
      <!--END accounted list-->
      <!--END table card--->
    </v-card>
    <DimensionsDialog
      :itemDialog="itemDialog"
      :selectedExpenseItem="cacheExpenseItem"
      v-on:close-dialog="closeDialogItem"
      :dimensionValues="dimensionValues"
      :displayImprestLineActions="displayImprestLineActions"
      @updateDimensions="updateDimensions"
    />
  </v-container>
</template>

<script>
import CalcImprest from "./CalcImprest.vue";
import AccountedList from "./accountedList.vue";
import ImprestMixin from "../ImprestMixin.js";
import DimensionsDialog from "./ImprestDimensionsDialog.vue";

export default {
  name: "ImprestAccounting",
  mixins: [ImprestMixin],
  components: {
    CalcImprest,
    AccountedList,
    DimensionsDialog,
  },
  data: function () {
    return {
      displayImprestLineActions: true,
      itemDialog: false,
      path: this.$router.currentRoute.meta.breadcrumb,
      imprestLine: {},
      detailedImprestLines: [],
      dialog: false,
      accountsDialog: false,
      accountedImprest: [],
      seen: false,
      expenseItem: {
        id: 0,
        documentType: "Imprest",
        expenseCode: undefined,
        description: undefined,
        documentNo: this.route?.params["code"],
        shortcutDimension1Code: "",
        shortcutDimension2Code: "",
        shortcutDimension3Code: "",
        shortcutDimension4Code: "",
        dimensionSpeedkeyCode: "",
        fundNo: "",
        globalDimension3Code: "",
        globalDimension4Code: "",
        globalDimension5Code: "",
        globalDimension6Code: "",
        globalDimension7Code: "",
        globalDimension8Code: "",
      },
      cacheExpenseItem: {},
    };
  },

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("imprest/getExpenseCodes");
      v.$store.dispatch("imprest/setBreadCrumbs", to);
      v.$store.dispatch("imprest/getDimensionValues");
      if (to.params.code) {
        v.$store.dispatch("imprest/getImprestsDetails", to.params.code);
      }
    });
  },

  watch: {
    $route(to, from) {
      this.path = to.meta.breadcrumb;
    },
  },

  computed: {
    headers() {
      return [
        { text: "#no", sortable: true, value: "expenseCode", align: "center" },
        {
          text: "Description",
          sortable: true,
          value: "description",
          align: "center",
        },
        {
          text: "Quantity",
          sortable: true,
          value: "Quantity",
          align: "center",
        },
        {
          text: `Original Amount ${this.Currency}`,
          sortable: true,
          value: "amountToPay",
          align: "center",
        },
        {
          text: `Balance ${this.Currency}`,
          sortable: true,
          value: "balance",
          align: "center",
        },
        { text: "Actions", sortable: false, value: "action", align: "start" },
      ];
    },

    imprest() {
      const imprests = this.$store.getters["imprest/imprestsDetails"];
      return imprests ? imprests.imprestLinesAPI : [];
    },
    imprestNo() {
      return this.$store.getters["imprest/imprestsDetails"].no;
    },
    Currency() {
      const data = this.$store.getters["imprest/imprestsDetails"];
      return data ? data.currencyCode : "KES";
    },
    expenseItems() {
      return this.$store.getters["imprest/expenseCodes"];
    },
    dimensionValues() {
      return this.$store.getters["imprest/imprestGetters"]("dimensions");
    },
    setDialog: {
      get() {
        return this.itemDialog;
      },
      set(val) {
        this.$emit("close-dialog", val);
      },
    },
  },
  methods: {
    closeDialogItem: function (val) {
      this.itemDialog = val;
    },
    submitAccount() {
      this.$confirm.show({
        title: "Confirm Accounting",
        text: "Confirm Submitting Accounted imprest lines",
        onConfirm: () => {
          const data = {
            docNo: this.imprestNo,
          };
          // data.imprestStatus = 'Accounted'
          this.$store.dispatch("imprest/settleImprest", data);
        },
      });
    },
    addDimention(item) {
      this.cacheExpenseItem = Object.assign({}, item);
      this.itemDialog = true;
    },
    saveAccountingItem(item) {
      (item.expenseCode = this.expenseItem.expenseCode),
        (item.description = this.expenseItem.description);
      const { expenseCode, description, documentNo } = item;
      this.$store.dispatch("imprest/saveAdditionImprestLineItem", {
        expenseCode,
        description,
        documentNo,
        documentType: this.expenseItem["documentType"],
        ...this.cacheExpenseItem,
      });
      this.cacheExpenseItem = {};
    },
    addNewAccountingItem() {
      let newItem = this.imprest.at(-1);
      if (
        this.expenseItem.expenseCode !== undefined ||
        this.expenseItem.description !== undefined ||
        newItem.extraLine
      ) {
        Event.$emit(
          "ApiError",
          "Please save the additional line item that is being edited first"
        );
        return;
      }
      for (let prop in newItem) {
        let propValue =
          prop !== "documentNo" ? (newItem[prop] = "") : newItem[prop];
        newItem = {
          ...newItem,
          [prop]: propValue,
          extraLine: true,
          id: this.imprest?.length,
        };
      }
      this.imprest.push(newItem);
    },
    autosaveExpenseItem() {},
    selectExpenseCode() {},
    saveAttachments() {
      this.accountedImprest = this.imprest;
      this.accountsDialog = true;
    },
    attach: function (code) {
      this.dialog = true;
      this.imprestLine = this.imprest.filter((i) => i.lineNo === code).shift();
    },
    closeDialog: function (val) {
      this.dialog = val;
    },
    closeAccountedList: function (val) {
      this.accountsDialog = val;
    },
    /**
     * @description get the imprest lines that are (not) accounted
     * @param item
     * @returns {string}
     */
    /**
     * Status color codes
     * @param item
     * @returns {string}
     */
    statusColorCode: (item) => {
      if (item.balance === 0 || item.balance < 0) {
        return "success";
      } else if (item.balance !== 0 && item.balance < item.amountToPay) {
        return "warning";
      } else {
        return "error";
      }
    },
    /**
     * hide button depending on status
     * @param item
     * @returns {boolean}
     */
    hideButton: (item) => {
      if (item.balance === 0 || item.balance < 0) {
        return true;
      } else return !(item.balance !== 0 && item.balance < item.amountToPay);
    },
    updateDimensions(val) {
      console.log("Added dimensions", val);
      const {
        shortcutDimension1Code,
        shortcutDimension2Code,
        shortcutDimension3Code,
        shortcutDimension4Code,
        dimensionSpeedkeyCode,
        globalDimension5Code,
        globalDimension6Code,
      } = val;
      this.cacheExpenseItem = Object.assign(
        {},
        {
          shortcutDimension1Code,
          shortcutDimension2Code,
          shortcutDimension3Code,
          shortcutDimension4Code,
          dimensionSpeedkeyCode,
          globalDimension5Code,
          globalDimension6Code,
        }
      );
    },
  },
};
</script>
<style scoped>
* {
  font-family: Poppins, sans-serif !important;
}
</style>
